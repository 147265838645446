import "./style.scss"
import Icons from "../Icons"
import Status from "../Status"
import { useEffect, useRef, useState } from "react"
import { DateRangePicker } from 'react-date-range';
import { format, formatISO, isExists } from 'date-fns';
import NoResult from "../../assets/svg/NoResults.svg"

const HistoryModal = ({OfferId, MerchantName, OfferTitle, OfferStatus, setHistoryDet, OffDet}) => {

    const [startDate,setStartDate] = useState('')
    const [endDate,setEndDate] = useState('')
    const [toggleCalendar,setToggleCalendar] = useState("")
    const [selectedOptions, setSelectedOptions] = useState([])
    const [dateState, setDateState] = useState([
      {
        startDate: new Date(),
        endDate: null,
        key: 'selection'
      }
    ]);

    const[downloadDDOpen,setDownloadDDOpen] = useState(false)
    const[FieldDD,setFieldDD] = useState(false)
    const[searchText,setSearchText] = useState('')
    const handleSearchChange = (event) => {
        setSearchText(event.target.value)
      };
    const [fieldValueList,setFieldValueList] = useState(['Status','Merchant Name','Offer Type','Channel'])
    const [selectedValues, setSelectedValues] = useState(new Set(fieldValueList))
    const FieldDDRef = useRef()
    const SDCalendarRef = useRef() 
    const EDCalendarRef = useRef() 
    
    useEffect(()=>{

    },[startDate,endDate,selectedValues])

    useEffect(()=>{
      const handleClickOutside = (event) =>{
        if (
          FieldDDRef.current &&
          !FieldDDRef.current.contains(event.target) &&
          !document.querySelector('.FilterItem')?.contains(event.target)
          ) {
          setFieldDD(false);
          }      
        if (
          SDCalendarRef.current &&
          !SDCalendarRef.current.contains(event.target) &&
          EDCalendarRef.current &&
          !EDCalendarRef.current.contains(event.target)
        ) {
          setToggleCalendar('');
          
          }
    
      }

      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
    },[])


    
    const handleFieldCheckboxChange = (event) => {
      const value = event.target.value;
      if (!selectedValues.has(value)) {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.add(value);
        setSelectedValues(newSelectedValues);
      } else {
        const newSelectedValues = new Set(selectedValues);
        newSelectedValues.delete(value);
        setSelectedValues(newSelectedValues);
      }
    }
    
    const handleOnchangeDate = (item,value) => {
      let cd = new Date().getTime()
      let ed = new Date(value.endDate).getTime()
      let selectedDate ;
      
      if(cd<ed)
        selectedDate = format(new Date(value.endDate),"dd MMM yyyy")
      else
        selectedDate = format(new Date(value.startDate),"dd MMM yyyy")
      if(item==="Start"){
        setStartDate(selectedDate)
      }
      else if(item==="End"){
        setEndDate(selectedDate)
      }
      setToggleCalendar('')
    }

    const handleFieldSelectAll =() =>{
      const filteredItems = fieldValueList.filter(item => 
        item.toLowerCase().includes(searchText.toLowerCase())
    );
      if (!isAllSelected()) {
        const newSelectedValues = new Set(selectedValues);
        filteredItems.forEach(item => newSelectedValues.add(item));
        setSelectedValues(newSelectedValues); 
      } else {
          const newSelectedValues = new Set(selectedValues);
          filteredItems.forEach(item => newSelectedValues.delete(item));
          setSelectedValues(newSelectedValues);
      }
    }  
    let tableHeader = [
        { label: 'Date & Time', value: 'DateNTime' },
        { label: 'User', value: 'User' },
        { label: 'Field', value: 'Field' },
        { label: 'Action', value: 'Action' },
        { label: 'Previous', value: 'Previous' },
        { label: 'New', value: 'New' },
        { label: 'Comments/ Review', value: 'CommentsReview' }
      ];

    function handleActionCall(){
    }

    const handleSelectAll =() => {
      if(OffDet.length === selectedOptions.length){
        setSelectedOptions([])
      }
      else{
        let ss = OffDet.map((item)=>item.id)
        setSelectedOptions(ss)
      }
    }
    const handleCheckboxChange = (option) => {
      if(selectedOptions.includes(option)){
        setSelectedOptions(selectedOptions.filter(item=>item!==option))
      }
      else{
        setSelectedOptions([...selectedOptions,option])
      }
      
    }    
    
    
    function isSubset(list1, list2) {
      return list1.some(item => list2.has(item));
    }
    function isSameset(list1, list2) {
      return list1.every(item => list2.has(item));
    }
  
    function isAllSelected(){
      const filteredItems = fieldValueList.filter(option => 
        option.toLowerCase().includes(searchText.toLowerCase())
    );
    return filteredItems.every(item => selectedValues.has(item)); 
    }    

    return <div className="HistoryModal">
        <div className="Maindiv">
            <div className="Heading">Offer History - {OfferId}
                <div className="RightIcons">
                    {OffDet.length >= 1 && <div style={{position: "relative"}} onClick={()=>{setDownloadDDOpen(!downloadDDOpen)}}>
                        <Icons name="download" width="32" height="32" />
                        {downloadDDOpen && 
                        <div className={`actions-dropdownlist`}>
                            <button className='action-new' onClick={() => {
                                handleActionCall("Download as .CSV");
                            }}>
                                Download as .CSV
                            </button>
                            <button className='action-new' onClick={() => {
                                handleActionCall("Download as .Pdf");
                            }}>
                                Download as .Pdf
                            </button>
                        </div>
                        } 
                     </div>}
                    <div onClick={()=>{setHistoryDet({});document.documentElement.style.overflow = 'auto'; }}><Icons name="cancel-grey"  width="32" height="32" /></div>
                </div>
            </div>
            <div className="Details">
            <div className="MerhantDet">
                {MerchantName} - {OfferTitle}
                <div className="OfferStatus">
                {(() => {
                    switch(OfferStatus.trim()) {
                      case "Live":
                        return <Status text="Live" textColor="#14532D" bgColor="#F0FDF4"/>;
                      case "Rejected":
                        return <Status text="Rejected" textColor="#DC2626" bgColor="#FEF2F2"/>;
                      case "Draft":
                        return <Status text="Draft" textColor="#374151" bgColor="#F3F4F6"/>;
                      case "Requested to Pause":
                        return <Status text="Requested to Pause" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Requested to Resume":
                        return <Status text="Requested to Resume" textColor="#B010B6" bgColor="#FFE8FB"/>;
                      case "Paused":
                        return <Status text="Paused" textColor="#CFAF0F" bgColor="#FEFFE8"/>;
                      case "Expired":
                        return <Status text="Expired" textColor="#CC5120" bgColor="#FFF3E8"/>;
                      case "Scheduled":
                        return <Status text="Scheduled" textColor="#002D8E" bgColor="#E6EDFD" />;
                      case "Resume InProgress":
                        return <Status text="Resume InProgress" textColor="#001E5E" bgColor="#C3E7FE"/>;
                      case "Pause InProgress":
                        return <Status text="Pause InProgress" textColor="#001E5E" bgColor="#C3E7FE" />;
                      case "Pending for Approval":
                        return <Status text="Pending for Approval" textColor="#D97706" bgColor="#FFFBEB"/>;
                      default:
                        return <Status text={OfferStatus} textColor="#CFAF0F" bgColor="#FFF3E8"/>;
                    }
                  })()}
                </div>
            </div>
            {OffDet.length >= 1 && <div className="Filters">
                  <div className="TimeStamp">
                    <div>Date : </div>
                    <div className="calendar" ><Icons name="calendar" width="24" height="24" /> </div>
                    <div ref={SDCalendarRef} className={`dateVal ${startDate ? 'act' : ''}`} onClick={(e)=>{
                      e.stopPropagation();
                      if (toggleCalendar === "Start") {
                        setToggleCalendar("");
                      } else if (toggleCalendar === "" || toggleCalendar === "End") {
                        setToggleCalendar("Start");
                      }}}>{startDate ? <>{startDate}</> :"Select Date"}
                      {toggleCalendar === "Start" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div >
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("Start",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          direction="horizontal"
                        />
                        </div>
                      </div>}
                    </div>
                    <div className="calendar-arrow"><Icons name="calendar-arrow" width="24" height="24" /></div> 
                    <div  ref={EDCalendarRef} className={`dateVal ${endDate ? 'act' : ''}`} onClick={(e)=>{
                      e.stopPropagation();
                      if (toggleCalendar === "End") {
                        setToggleCalendar("");
                      } else if (toggleCalendar === "" || toggleCalendar === "Start") {
                        setToggleCalendar("End");
                      }}}>{endDate ? <>{endDate}</> :"Select Date"}
                      {toggleCalendar==="End" && <div style={{position:"absolute", right:"0", zIndex:"50"}}>
                        <div>
                        <DateRangePicker
                          editableDateInputs={true}
                          onChange={item => handleOnchangeDate("End",item.selection)}
                          focusedRange={[0,1]}
                          moveRangeOnFirstSelection={false}
                          ranges={dateState}
                          showSelectionPreview={true}
                          months={1}
                          minDate={startDate ? new Date(startDate) :new Date("01 Jan 1924")}
                          direction="horizontal"
                        /></div>
                      </div>}
                    </div>
                  </div>
                  <div className="Separator">|</div>
                  <div className="FilterItem" onClick={(e)=>{e.stopPropagation();setFieldDD(!FieldDD)}}>Field & Action : All <Icons name="nav-arrow-down" height="20" width="20" /> </div>

                  <div>
                    {FieldDD &&
                    <div ref={FieldDDRef} className="FieldDD">
                      <div className='drop-search'>
                        <div><Icons
                          name="search"
                          width="18"
                          height="18"
                          fill="#6B7280"
                        /></div>
                        <div className='dropdown-inputbox'>
                        <input 
                            type="text" 
                            placeholder="Search..." 
                            value={searchText}
                            onChange={(e) => handleSearchChange(e)}
                            className="dropdown-search"
                          />
                        </div>
                      </div>
                      <div className='hr-line ml-10 mb-24'></div>
                    <div className="dropdown custom-scrollbar">
                      
                      {fieldValueList.filter(item=>item.toLowerCase().includes(searchText.toLowerCase())).length >= 1 ? <label className={`container`}>
                        <input
                          type="checkbox"
                          checked={selectedValues.size >=1 && isSubset(fieldValueList.filter(item=>item.toLowerCase().includes(searchText.toLowerCase())),selectedValues)}
                            onChange={() => handleFieldSelectAll()}
                        />
                        <span className={`${selectedValues.size >=1 && isSameset(fieldValueList.filter(item=>item.toLowerCase().includes(searchText.toLowerCase())),selectedValues) ? "checkmark1" :"checkmark2"}`}></span>
                        All
                      </label> : <div className ="noitems">No Items match your search</div>}
                      <div className='val-dropdown'>
                      {fieldValueList?.filter(option => option.toLowerCase().includes(searchText.toLowerCase()))
                      .map(option => (
                        <div>
                        <label className="container" key={option}>
                          <input
                            type="checkbox"
                            value={option}
                            checked={selectedValues?.has(option)}
                            onChange={(e) => handleFieldCheckboxChange(e)}
                          />
                          <span className="checkmark1"></span>
                          {option}
                        </label>
                        </div>
                        ))}
                      </div>
                    </div>
                  </div>}
                </div>
              </div>}
            {OffDet.length >= 1 ? <div className="HistoryTable">
              <table>
                  <thead>
                      <td>
                      <label className="container">
                          <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={selectedOptions?.length >= 1}
                          />
                          <span className={selectedOptions.length === OffDet.length ? "checkmark1" : "checkmark2"}></span>
                      </label>
                      </td>
                      {tableHeader && tableHeader.map(
                      ({ label, value }) => {
                          return (<td>
                          <div style={{cursor:value==="action"&&"default"}}>
                              {label}
                              {
                              value !== 'action' &&
                              <span className='sort' >
                                  <div><Icons name="arrow-up" width={6} height={8} /></div>
                                  <div><Icons name="arrow-down" width={6} height={8} /></div>
                              </span>
                              }
                          </div>
                          </td>)
                      }
                      )}
                  </thead>
                  {OffDet.length === 0 ? 
                      <div className='no-result'>
                      <div className='no-img'><img src={NoResult} alt="no-result" height={180} width={155}/></div>
                      <div className='nor-text'>Sorry, no results found :(</div>
                      <div className='nor-text'>Try Searching again</div>
                      </div>
                      :
                  <tbody>
                      {OffDet.map((offerDetails) => {
                      return (<tr>
                          <td style={{width:"4%"}}>
                          <label className="container">
                          <input
                              type="checkbox"
                              checked={selectedOptions?.includes(offerDetails.id)}
                              onChange={() => {handleCheckboxChange(offerDetails.id)}}
                          />
                          <span className="checkmark1"></span>
                          </label>
                          </td>
                          <td style={{width:"14%"}}>{offerDetails.DateNTime}</td>
                          <td style={{width:"11%"}}>{offerDetails.User}</td>
                          <td style={{width:"11%"}}>{offerDetails.Field}</td>
                          <td style={{width:"14%"}}>{offerDetails.Action}</td>
                          <td style={{width:"14%"}}>{offerDetails.Previous}</td>
                          <td style={{width:"14%"}}>{offerDetails.New}</td>
                          <td style={{width:"18%"}}>{offerDetails.CommentsReview}</td>
                      </tr>)
                      })}
                  </tbody>
                  }
              </table>
            </div> : 
              <div className="NoResults">
                <img src={NoResult} width="300" height="300"/>
                <div> No offer history available.<br />
                This offer was created outside of the Offer Management Platform </div>
              </div>}
          </div>
        </div>
    </div>
}

export default HistoryModal