import Icons from "../Icons"
import "./style.scss"

const Status = ({text, bgColor, textColor, icon, warningMsg, warningMsgDefaultText,index }) =>{
return <button className="status-label" style={{color:textColor, backgroundColor:bgColor}}>
{warningMsg ?
  <div style={{display:'flex',position:'relative', alignItems:'center', gap:'7px'}}>
    <Icons name={icon} fill={textColor} height="18" width="18" /><span>{text}</span>
    {warningMsg !== '' && 
        <div className = {`notification-reason ${index===9 || index===8 ? "up" : "down"} `}>
          <div className={`notification-arrow ${index===9 || index===8  ? "up" : "down"} `}><Icons name="notiarrow" width="12px" height="6px"/></div>
          <div className='notification-message'>{warningMsgDefaultText}
            <div style={{color:'#D1D5DB', fontWeight:"400", marginTop:"4px"}}>{warningMsg}</div>
          </div>
        </div>
    }
  </div>:
  <>{text}</>
}
</button>
}
export default Status