// AuthService.js
import Config from "./config";
import axios from 'axios';

const apiUrl = `${Config.domain}${Config.version}`;

export const signUp = async (data) => {
      let response = await fetch(apiUrl+'/auth/signUp', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
          },
        body: JSON.stringify(data)
      })
      return response
};

export const signIn = async (data) => {
        let response = await fetch(apiUrl+'/auth/login', {
            method:"POST",
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify(data)
          })
          
        return response
    };

export const populateCountry = async () => {
    let response = await fetch(apiUrl+'/list/countries', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
      },
    })
    if (response.status === 200) {
              response = await response.json();
              return response
    }
    return null;
}
export const verifyEmail = async (email,token) => {
    let response = await fetch(apiUrl+'/auth/verifyEmail', {
        method:"POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
        "email": email,
        "token": token
      })})
    return response
}

//API Services
let cancelToken;
export const getTypeAhead = async (url) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
    const response = await axios.get(Config?.domain + Config?.version + url, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      cancelToken: cancelToken.token
  });
  return response.data;
};

export const postTypeAhead = async (url, params = {}, hideAuth) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  const response = await axios.post(Config?.domain + Config?.version + url, params, {
    headers: hideAuth ?
      {
        'Content-Type': 'application/json'
      }
      :
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    cancelToken: cancelToken.token
  });
  const data = await response.data;
  return data;
};
export const postTypeAheadNew = async (url, params = {}, hideAuth) => {
  try {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    const response = await axios.post(Config?.domain + Config?.version + url, params, {
      headers: hideAuth ?
        {
          'Content-Type': 'application/json'
        }
        :
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      cancelToken: cancelToken.token
    });
    return response;
  }
  catch(e) {
    console.log(e)
    return e?.response
  }
};

export const getApiCall = async (url) => {
    const response = await axios.get(Config?.domain + Config?.version + url, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
  });
  return response.data;
};
export const getApiCallNew = async (url) => {
  try {
  const response = await axios.get(Config?.domain + Config?.version + url, {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  });
  return response;
  }
  catch(e) {
    console.log(e)
    return e?.response?.data
  }
};

export const postApiCall = async (url, params = {}, hideAuth) => {
  const response = await fetch(Config?.domain + Config?.version + url, {
    method: 'POST',
    headers: hideAuth ?
      {
        'Content-Type': 'application/json'
      }
      :
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    body: JSON.stringify(params)
  });
  const data = await response.json();
  return data;
};

export const postApiCallNew = async (url, params = {}, hideAuth) => {
  try {
  const response = await fetch(Config?.domain + Config?.version + url, {
    method: 'POST',
    headers: hideAuth ?
      {
        'Content-Type': 'application/json'
      }
      :
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    body: JSON.stringify(params)
  });
  return response;
  }
  catch(e) {
    console.log(e)
  }
};

export const fileUpload = async (url, formData) => {
  try {
    const response = await axios.post(Config?.domain + Config?.version + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response
  } catch (error) {
    console.error('Error uploading file:', error);
    return error?.response?.data
  }
  // const response = await fetch(Config?.domain + Config?.version + url, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //       'Authorization': `Bearer ${localStorage.getItem('token')}`
  //     },
  //   body: JSON.stringify({"file":params})
  // });
  // const data = await response.json();
};

export const putApiCall = async (url, params = {}, hideAuth) => {
    const response = await fetch(Config?.domain + Config?.version + url, {
        method: 'PUT',
        headers: hideAuth ?
        {
          'Content-Type': 'application/json'
        }
        :
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(params)
    });
    const data = await response.json();
    return data;
};

export const putApiCallNew = async (url, params = {}, hideAuth) => {
  try {
  const response = await fetch(Config?.domain + Config?.version + url, {
      method: 'PUT',
      headers: hideAuth ?
      {
        'Content-Type': 'application/json'
      }
      :
      {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(params)
  });
  return response;
  }
  catch(e) {
    console.log(e)
  }
};
